import React from "react";
import { Tooltip } from "./Tooltip";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

const Footer = () => {
  return (
    <footer
      id="footer"
      className={
        "section  " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : "bg-dark footer-text-light ")
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 align-self-center text-center text-lg-start">
            <p className="mb-0">
              © 2024{" "}
              <a
                href="/"
                className="link-underline link-underline-opacity-0-hover"
              >
                Cessabit Games LLC
              </a>
              . All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-2 align-self-center justify-content-center justify-content-lg-start">

          </div>
          <div className="col-lg-6 align-self-center">
            <ul className="nav nav-separator nav-separator-light justify-content-center justify-content-lg-end">
              <li className="nav-item">
                {" "}
                <a
                    className="nav-link"
                    target="_blank"
                    href="https://cessabit.games/termsofuse.html"
                >
                  Terms of Use
                </a>
              </li>
              <li className="nav-item">
                {" "}
                <a
                    className="nav-link"
                    target="_blank"
                    href="https://cessabit.games/privacypolicy.html"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                {" "}
                <a
                    className="nav-link"
                    target="_blank"
                    href="https://cessabit.games/cookiepolicy.html"
                >
                  Cookie policy
                </a>
              </li>
              <li className="nav-item">
                {" "}
                <a
                    className="nav-link"
                    target="_blank"
                    href="https://cessabit.games/listofpartners.html"
                >
                  List of partners
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
