import React from "react";

import Slider from "react-slick";
import {HeaderConfig, appliedConfig, appleAppStoreLink, googlePlayStoreLink} from "../config/commonConfig";

const Testimonials = () => {
  const reviews = [
    {
      name: "Yatzy Dice Game",
      src: "images/games/1.png",
      desc: "Yatzy is a free board dice games. "+
            "A multiplayer that you can play with bubbies and fun offline game you can play anywhere. " +
            "Yachty is a classic time killer for you and fantastic family pastime. ",
      gp: "https://play.google.com/store/apps/details?id=net.playwithworld.yatzy.dice.android",
      ios: "https://apps.apple.com/app/yatzy-dice-game-for-buddies/id1141547145"
    },
    {
      name: "Farkle - dice game online",
      src: "images/games/2.png",
      desc: "Play craps - Farkle Dice Game with buddies! \n" +
          "The rules of Farkle craps are very simple: \n" +
          "In 'Multiplayer' mode, you need to accumulate 5000 or 7500 points before your buddies do. \n" +
          "In 'Single Player' and 'Tournament', you need to accumulate as many points as you can in 10 moves. \n" +
          "\n",
      gp: "https://play.google.com/store/apps/details?id=net.playwithworld.farkle.dice.android",
      ios: "https://apps.apple.com/app/farkle-craps-dice-game-online/id1154643370"
    },
    {
      name: "Mancala - Classic Board Game",
      src: "images/games/3.png",
      desc: "New Mancala Game is an opportunity to train the logic, mental calculation and the brain in general in any free minute!.",
      gp: "https://play.google.com/store/apps/details?id=com.play.mancala.board.game",
      ios: "https://www.apple.com/in/app-store/"
    },
    {
      name: "Destroy Numbers Sliding Puzzle",
      src: "images/games/4.jpg",
      desc: "Tap and slide a number tile to combine it with an adjacent tile. If two tiles have different numbers, then they add up together. If two tiles have the same number, combining them will destroy them both.",
      gp: "https://play.google.com/store/apps/details?id=com.play.mancala.board.game",
      ios: "https://www.apple.com/in/app-store/"
    },

  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section
      id="our_games"
      className={
        "section bg-light" +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Our games</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          Play our exciting games and have fun!
        </p>
        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((review, index) => (
                <div
                    className="item testimonial border rounded text-center p-4"
                    key={index}
                >
                <span className="text-9">
                  <h3>{review.name}</h3>
                </span>

                  <img
                      className="img-fluid d-inline-block w-auto"
                      src={review.src}
                      alt=""
                  />

                  <p>{review.desc}</p>

                  <span className="text-body-tertiary text-2">
                  Download the app today.
                  </span>
                  <br></br>

                  <div className="d-inline-flex pt-2">
                    <a
                        className="me-4"
                        href={review.ios}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <img
                          className="img-fluid"
                          src="images/app-store.png"
                          alt=""
                      />
                    </a>
                    <a
                        href={review.gp}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <img
                          className="img-fluid"
                          src="images/google-play-store.png"
                          alt=""
                      />
                    </a>
                  </div>
                </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
