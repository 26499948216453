import React from "react";
import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  appliedConfig,
} from "../config/commonConfig";
const AboutUs = () => {
  return (
    <section
      id="about"
      className={
        "section bg-light" +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div
        className={
          "container " +
          (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
          appliedConfig.appliedIntroContent === IntroContentConfig.Image
            ? "pt-5 "
            : " ")
        }
      >
        <h2 className="text-9 fw-600 text-center">Our vision</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />

        <div className="row">
          <div className="col-lg-6 text-center">
            {" "}
            <img className="img-fluid" src="images/main_logo.png" alt=""  width="400px"/>{" "}
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <p>
              Our vision is to be a trailblazing force in the gaming industry, known for crafting innovative and visually stunning games that captivate players worldwide. We believe in the power of storytelling, artistry, and cutting-edge technology to push the boundaries of what's possible in gaming. We are dedicated to creating immersive experiences that not only entertain but also inspire and connect communities around the globe. With a relentless commitment to excellence and creativity, we strive to set new standards in the world of interactive entertainment.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
