export const HeaderConfig = {
  DefaultHeader: "Default Header",
  ClassicHeader: "Classic Header",
  SideHeaderLight: "Side Header Light",
  SideHeaderDark: "Side Header Dark",
  OverlayMenu: "Overlay Menu",
};

export const IntroThemeConfig = {
  Intro1: "Intro1",
  Intro2: "Intro2",
};

export const IntroBgConfig = {
  Image: "Image",
  Video: "Video",
  Default: "Default",
};

export const IntroContentConfig = {
  Image: "Image",
  Video: "Video",
};

export const appliedConfig = {
  // apply Header : DefaultHeader | ClassicHeader | SideHeaderLight | SideHeaderDark | OverlayMenu
  appliedHeader: HeaderConfig.DefaultHeader,

  // apply Intro theme from : Intro1 | Intro2
  appliedIntro: IntroThemeConfig.Intro2,

  // apply Intro background from : Image | Video | Default
  appliedIntroBg: IntroBgConfig.Image,

  //apply Content in Intro from: Image | Video
  appliedIntroContent: IntroContentConfig.Image,
};

// your Google play store and apple app store links
export const appleAppStoreLink = "https://www.apple.com/in/app-store/";
export const googlePlayStoreLink = "https://play.google.com/store/apps/developer?id=Cessabit+Games&hl=en";

// Mailchimp Config (Newsletter)
const mailChimp_u = "2d55d9b4ed893e467df3502e0";
const mailChimp_id = "b9ae90f5f4";

export const mailChimpUrl = `https://gmail.us1.list-manage.com/subscribe/post?u=${mailChimp_u}&amp;id=${mailChimp_id}`;

// Emailjs Config (Contact Form)
export const emailjsConfig = {
  templateId: "template_l5s5xf3",
  serviceId: "service_ttv80nt",
  userId: "TgHgtPufLUo0FAHeA",
};

export const scrollDuration = 1000;
