import React from "react";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

function Features() {
  return (
    <section
      id="features"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">What Sets Us Apart</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto"/>
        <br></br>
        <div className="row">
          <div className="col-lg-1 text-center mb-2 mb-xl-0">

          </div>
          <div className="col-lg-4 mb-4 align-self-center">
            <div className="row justify-content-center">

              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-pencil-ruler"/>
                  </div>
                  <h3>Creativity Meets Technology</h3>
                  <p>
                    We combine artistic creativity with technical expertise to create games that not only look and
                    sound amazing but also provide engaging gameplay experiences.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-gamepad"/>
                  </div>
                  <h3>Passion for Gaming</h3>
                  <p>
                    We're not just a company; we're a group of gamers at heart. Our dedication to gaming fuels our
                    commitment to excellence in every project.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 text-center mb-2 mb-xl-0">

          </div>
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-users"/>
                  </div>
                  <h3>Player-Centric Approach</h3>
                  <p>
                    At the core of our development process is a deep understanding of our players' desires and needs. We
                    prioritize player feedback and continuously refine our games to ensure they not only meet but exceed
                    expectations, creating experiences that resonate on a personal level.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-12">
                  <div className="featured-box style-3 mb-5">
                    <div className="featured-box-icon bg-body-secondary rounded-circle">
                      <i className="fas fa-lightbulb"/>
                    </div>
                    <h3>Endless Innovation</h3>
                    <p>
                      We're never content with the status quo. Our team constantly seeks new ways to innovate, staying
                      at
                      the forefront of emerging technologies and gaming trends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
